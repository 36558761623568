/*
 * @Descripttion:
 * @version:
 * @Author: guomengxia
 * @Date: 2023-02-28 15:51:53
 * @LastEditors: wenboliu
 * @LastEditTime: 2024-07-23 17:29:04
 */
// 金大盘价
import { get, post } from './http'
// 分页查询所有
const path = '/merits'
const API: any = {
  // 绩效配置-项目配置
  projectConfigLists: path + '/project/queryAll', // 项目分页查询列表
  projectConfigAdd: path + '/project/add', // 项目新增列表
  projectConfigEdit: path + '/project/update', // 项目编辑
  projectConfigDelete: path + '/project/delete', // 项目删除
  projectConfigStopStartStatus: path + '/project/updateStatus', // 项目启用、停用
  // 绩效配置-指标配置
  metricConfigDict: path + '/meritsdict/queryDictAll', // 项目分页查询列表
  metricConfigPrijectSelect: path + '/project/selectAll', // 指标配置-项目模块下拉框
  metricConfigQueryList: path + '/meritsnorm/queryAll', // 列表查询
  metricConfigAdd: path + '/meritsnorm/add', // 指标新增
  metricConfigDelete: path + '/meritsnorm/delete', // 指标批量删除
  metricConfigUpdate: path + '/meritsnorm/update', // 指标批量编辑
  // 考核方案
  querySchemeSubject: path + '/meritsScheme/querySchemeSubject', // 查询方案
  saveSchemeSubject: path + '/meritsScheme/saveSchemeSubject', // 保存方案
  querySubjectDetails: path + '/meritsScheme/querySubjectDetails', // 查询方案详情
  removeSchemeSubject: path + '/meritsScheme/removeSchemeSubject', // 删除方案
  queryAllNotPage: path + '/meritsnorm/queryAllNotPage', // 根据项目ID查询指标
  // 绩效管理
  meritsManageQueryList: path + '/merits/queryByPage', // 配置管理查询列表
  meritsManagedetail: path + '/merits/detail', // 配置管理详情
  meritsManageSubmit: path + '/merits/submit', // 配置管理提交
  meritsManageEditDetail: path + '/merits/detailEdit', // 配置管理编辑
  meritsManageCheckSuggest: path + '/merits/passOrReject', // 审批
  mertisPanelPersonContrust: '/user/user/selectAll', // 处罚提交人员下拉
  mertisRelaunch: path + '/merits/relaunch/', // 重新发起
  detailDelete: path + '/merits/detailDelete', // 绩效详情删除
  // 处罚明细
  scoreDetailQueryList: path + '/meritsdetail/selectMeritsDetailByParam', // 处罚明细
  scoreDeatilConfirmBtn: path + '/merits/confirm', // 处罚确认
  // 问题集 / 绩效列表
  queryAllArea: path + '/merits/queryAllArea', // 查询区域
  queryShopByAreaId: path + '/merits/queryShopByAreaId', // 根据区域查询门店
  ueryPerformanceProblem: path + '/performanceProblem/queryPerformanceProblem', // 查询绩效列表/问题集
  savePerformanceProblem: path + '/performanceProblem/savePerformanceProblem', // 保存接口 绩效列表/问题集
  changeTheState: path + '/performanceProblem/changeTheState', // 更改状态接口 绩效列表/问题集
  removePerformanceProblem: path + '/performanceProblem/removePerformanceProblem', // 删除绩效列表/问题集
  exportPerformanceProblem: path + '/performanceProblem/exportProblem', // 导出 绩效列表/问题集
  performanceProblemDetails: path + '/performanceProblem/queryPerformanceProblemDetails', // 查询 绩效列表/问题集 详情
  updatePerformanceProblem: path + '/performanceProblem/updatePerformanceProblem', // 编辑绩效列表
  queryMeritsAssess: path + '/meritsAssess/queryMeritsAssess', // 考核列表查询
  querySchemeSubjectNotPage: path + '/meritsScheme/querySchemeSubjectNotPage', // 考核方案下拉
  launchMeritsAssess: path + '/meritsAssess/launchMeritsAssess', // 发起考核
  queryAssessDetails: path + '/meritsAssess/queryAssessDetails', // 查询考核方案
  queryAssessScore: path + '/meritsAssess/queryAssessScore', // 查询方案得分情况
  exportAssessScore: path + '/meritsAssess/exportAssessScore', // 导出方案得分情况
  removeMeritsAssess: path + '/meritsAssess/removeMeritsAssess', // 删除考核方案
  removeAssessScore: path + '/meritsAssess/removeAssessScore', // 删除个人方案得分
  queryPerformanceReporting: path + '/meritsAssess/queryPerformanceReporting', // 查询绩效报告
  queryPerformanceManagement: path + '/meritsAssess/queryPerformanceManagement', // 查询绩效明细
  notarizeAssessScore: path + '/meritsAssess/notarizeAssessScore', // 方案确认
  remindMeritsAssess: path + '/meritsAssess/remindMeritsAssess', // 提醒考核确认
  remindPerformanceProblem: path + '/performanceProblem/remindPerformanceProblem', // 提醒问题集/绩效列表
  queryAssessUserState: path + '/meritsAssess/queryAssessUserState', // 查询状态
  exportPerformanceReporting: path + '/meritsAssess/exportPerformanceReporting',//导出绩效列表
  // 绩效报告
  meritsReportQueryList: path + '/merits/performanceReport', // 绩效报告列表
  // 美工对接
  artDockingList: path + '/networkArtPerformance/selectListByPage', // 美工对接查询列表
  artDockingInsert: path + '/networkArtPerformance/insert', // 美工对接新增
  artDockingEdit: path + '/networkArtPerformance/updateInfoById', // 美工对接编辑
  artDockingDelete: path + '/networkArtPerformance/deleteBatchById', // 美工对接删除
  artDockingDetail: path + '/networkArtPerformance/selectOneInfoById', // 美工对接详情
  artConfirmDocking: path + '/networkArtPerformance/confirmDocking', // 确认对接
  artDockReminders: path + '/networkArtPerformance/reminders', // 催办
  artDockTurnOver: path + '/networkArtPerformance/handOverArt', // 移交
  artDockFeekback: path + '/networkArtPerformance/feedBack', // 反馈
  artDockDesigner: path + '/networkArtPerformance/artConfirmFinish', // 待美工完成
  artDockUserConfirm: path + '/networkArtPerformance/dockingConfirm', // 待对接人确认
  artConfirmImgNumber: path + '/networkArtPerformance/confirmImageNum', // 确认图片数量
  artDivisionDayReport: path + '/networkArtPerformance/reportByDay', // 分日总表
  artDivisionMonthReport: path + '/networkArtPerformance/reportByMonth', // 分月总表
  artDeptList: path + '/networkArtPerformance/getDropList', // 获取部门下拉选
  artDockExport: path + '/networkArtPerformance/report', // 美工绩效导出
  artDivisionDayExport: path + '/networkArtPerformance/reportByDay/report', // 分日总表导出
  artDivisionMonthExport: path + '/networkArtPerformance/reportMonth', // 分日总表导出
  urlRepetition: path + '/networkArtPerformance/urlRepetition'// 判断使用链接是否重复

}

/// // 绩效配置-项目配置
export const projectConfigListsApi = (data?: any) => post(API.projectConfigLists, data, '')
export const projectConfigAddApi = (data: any) => post(API.projectConfigAdd, data, '')
export const projectConfigEditApi = (data: any) => post(API.projectConfigEdit, data, '')
export const projectConfigDeleteApi = (data: any) => post(API.projectConfigDelete, data, '')
export const projectConfigStopStartStatusApi = (data: any) => post(API.projectConfigStopStartStatus, data, '')
export const querySchemeSubjectApi = (data: any) => post(API.querySchemeSubject, data, '')
export const saveSchemeSubjectApi = (data: any) => post(API.saveSchemeSubject, data, '')
export const querySubjectDetailsApi = (data: any) => post(API.querySubjectDetails, data, '')
export const removeSchemeSubjectApi = (data: any) => post(API.removeSchemeSubject, data, '')

// 绩效配置-指标配置
export const metricConfigDictApi = (data: any) => get(API.metricConfigDict, data, '')
export const metricConfigPrijectSelectApi = () => get(API.metricConfigPrijectSelect, '', '')
export const metricConfigQueryListApi = (data: any) => post(API.metricConfigQueryList, data, '')
export const metricConfigAddApi = (data: any) => post(API.metricConfigAdd, data, '')
export const metricConfigDeleteApi = (data: any) => post(API.metricConfigDelete, data, '')
export const metricConfigUpdateApi = (data: any) => post(API.metricConfigUpdate, data, '')
export const meritsManageQueryListApi = (data: any) => get(API.meritsManageQueryList, data, '')
export const meritsManagedetailApi = (data: any) => get(API.meritsManagedetail, data, '')
export const meritsManageSubmitApi = (data: any) => post(API.meritsManageSubmit, data, '')
export const meritsManageEditDetailApi = (data: any) => post(API.meritsManageEditDetail, data, '')
export const mertisRelaunchApi = (data: any) => post(API.mertisRelaunch, data, '')
export const detailDeleteApi = (data: any) => post(API.detailDelete, data, '')

// 处罚明细
export const scoreDetailQueryListApi = (data: any) => get(API.scoreDetailQueryList, data, '')
export const meritsManageCheckSuggestApi = (data: any) => post(API.meritsManageCheckSuggest, data, '')
export const scoreDeatilConfirmBtnApi = (data: any) => post(API.scoreDeatilConfirmBtn, data, '')
export const meritsReportQueryListApi = (data: any) => get(API.meritsReportQueryList, data, '')
export const mertisPanelPersonContrustApi = (data: any) => get(API.mertisPanelPersonContrust, data, '')
// 问题集 / 绩效列表
export const queryAllAreaApi = () => get(API.queryAllArea, '', '')
export const queryShopByAreaIdApi = (data: any) => get(API.queryShopByAreaId, data, '')
export const ueryPerformanceProblemApi = (data: any) => post(API.ueryPerformanceProblem, data, '')
export const savePerformanceProblemApi = (data: any) => post(API.savePerformanceProblem, data, '')
export const changeTheStateApi = (data: any) => post(API.changeTheState, data, '')
export const removePerformanceProblemApi = (data: any) => post(API.removePerformanceProblem, data, '')
export const exportPerformanceProblemApi = (data:any) => post(API.exportPerformanceProblem, data, {
  responseType: 'blob'
})
export const queryAllNotPageApi = (data: any) => post(API.queryAllNotPage, data, '')
export const performanceProblemDetailsApi = (data: any) => post(API.performanceProblemDetails, data, '')
export const updatePerformanceProblemApi = (data: any) => post(API.updatePerformanceProblem, data, '')
export const queryMeritsAssessApi = (data:any) => post(API.queryMeritsAssess, data, '')
export const querySchemeSubjectNotPageApi = () => get(API.querySchemeSubjectNotPage, '', '')
export const launchMeritsAssessApi = (data:any) => post(API.launchMeritsAssess, data, '')
export const queryAssessDetailsApi = (data:any) => get(API.queryAssessDetails, data, '')
export const queryAssessScoreApi = (data:any) => post(API.queryAssessScore, data, '')
export const exportAssessScoreApi = (data:any) => post(API.exportAssessScore, data, {
  responseType: 'blob'
})
export const removeMeritsAssessApi = (data:any) => post(API.removeMeritsAssess, data, '')
export const removeAssessScoreApi = (data:any) => post(API.removeAssessScore, data, '')
export const queryPerformanceReportingApi = (data:any) => post(API.queryPerformanceReporting, data, '')
export const queryPerformanceManagementApi = (data:any) => post(API.queryPerformanceManagement, data, '')
export const notarizeAssessScoreApi = (data:any) => get(API.notarizeAssessScore, data, '')
export const remindMeritsAssessApi = (data:any) => post(API.remindMeritsAssess, data, '')
export const remindPerformanceProblemApi = (data:any) => post(API.remindPerformanceProblem, data, '')
export const queryAssessUserStateApi = (data:any) => get(API.queryAssessUserState, data, '')
export const exportPerformanceReportingApi = (data:any) =>post(API.exportPerformanceReporting, data, {
  responseType: 'blob'
})
// 美工对接
export const artDockingListApi = (data: any) => post(API.artDockingList, data, '')// 美工对接列表
export const artDockingInsertApi = (data: any) => post(API.artDockingInsert, data, '')// 美工对接新增
export const artDockingEditApi = (data: any) => post(API.artDockingEdit, data, '')// 美工对接编辑
export const artDockingDeleteApi = (data: any) => post(API.artDockingDelete, data, '')// 美工对接删除
export const artDockingDetailApi = (data: any) => get(API.artDockingDetail, data, '')// 美工对接详情
export const artConfirmDockingApi = (data: any) => post(API.artConfirmDocking, data, '')// 确认对接
export const artDockRemindersApi = (data: any) => post(API.artDockReminders, data, '')// 催办
export const artDockTurnOverApi = (data: any) => post(API.artDockTurnOver, data, '')// 移交
export const artDockFeekbackApi = (data: any) => post(API.artDockFeekback, data, '')// 反馈
export const artDockDesignerApi = (data: any) => post(API.artDockDesigner, data, '')// 待美工完成
export const artDockUserConfirmApi = (data: any) => post(API.artDockUserConfirm, data, '')// 待对接人确认
export const artConfirmImgNumberApi = (data: any) => post(API.artConfirmImgNumber, data, '')// 确认图片数量
export const artDivisionDayReportApi = (data: any) => post(API.artDivisionDayReport, data, '')/// 分日总表
export const artDivisionMonthReportApi = (data: any) => post(API.artDivisionMonthReport, data, '')// 分月总表
export const artDeptListApi = (data: any) => post(API.artDeptList, data, '')//  获取部门下拉选
export const urlRepetitionApi = (data: any) => post(API.urlRepetition, data, '')
export const artDockExportApi = (data: any) => post(API.artDockExport, data, {
  responseType: 'blob'
})//  美工绩效导出
export const artDivisionDayExportApi = (data: any) => post(API.artDivisionDayExport, data, {
  responseType: 'blob'
})/// 分日总表导出
export const artDivisionMonthExportApi = (data: any) => post(API.artDivisionMonthExport, data, {
  responseType: 'blob'
})// 分日总表导出
