/*
 * @Descripttion:
 * @version:
 * @Author: wenboliu
 * @Date: 2024-03-17 14:14:50
 * @LastEditors: wenboliu
 * @LastEditTime: 2024-04-01 14:55:44
 */
// 金大盘价
import { get, post, postFormData } from './http'
// 分页查询所有
const path = '/merits'
const API: any = {
  // 组织架构员工列表
  userList: '/user/user/selectAll',
  // 获取组织架构部门列表
  getDept: '/user/department/tree',
  // 获取状态列表
  statusList: path + '/salary/findDropdownBoxByType',
  // 查询工资条列表
  querySalaryList: path + '/salary/querySalaryList',
  // 详情查询
  querySalaryDetails: path + '/salary/querySalaryDetails',
  // 详情查询
  queryRedactSalaryDetails: path + '/salary/queryRedactSalaryDetails',

  // 保存编辑的信息
  saveSalaryDetails: path + '/salary/saveSalaryDetails',
  // 删除编辑的信息
  removeSalaryDetails: path + '/salary/removeSalaryDetails',
  // 填写完成情况，数量统计
  queryQuantityStatistics: path + '/salary/queryQuantityStatistics',
  // 未提交人员信息
  uncommittedUser: path + '/salary/uncommittedUser',
  // 导出填写模版
  exportUserinfoTemplate: path + '/salary/exportUserinfoTemplate',
  // 导出详情
  exportSalaryDetails: path + '/salary/exportSalaryDetails',
  // 税务导出详情
  exportSalaryDetailsTaxBureau: path + '/salary/exportSalaryDetailsTaxBureau',
  // 人事导出详情
  exportSalaryDetailsPersonnel: path + '/salary/exportSalaryDetailsPersonnel',
  // 导入员工填报数据
  importUserinfo: path + '/salary/importUserinfo',
  // 查询工资条设置
  querySalaryConfiguration: path + '/salary/querySalaryConfiguration',
  // 保存工资条设置
  saveSalaryConfiguration: path + '/salary/saveSalaryConfiguration',
  // 校验权限状态
  checkBlenosis: path + '/salary/checkBlenosis',
  // 发送待办提醒
  remindSalary: path + '/salary/remindSalary',
  // 部门负责人查询
  queryDeptPrincipalUser: path + '/salary/queryDeptPrincipalUser'

}

export const userListApi = (data: any) => get(API.userList, data, '')
export const getDeptApi = () => get(API.getDept, '', '')
export const statusListApi = (data: any) => get(API.statusList, data, '')
export const querySalaryListApi = (data: any) => post(API.querySalaryList, data, '')
export const queryQuantityStatisticsApi = (data: any) => post(API.queryQuantityStatistics, data, '')
export const querySalaryDetailsApi = (data: any) => post(API.querySalaryDetails, data, '')
export const saveSalaryDetailsApi = (data: any) => post(API.saveSalaryDetails, data, '')
export const removeSalaryDetailsApi = (data: any) => post(API.removeSalaryDetails, data, '')
export const uncommittedUserApi = (data:any) => post(API.uncommittedUser, data, '')
export const exportUserinfoTemplateApi = () => get(API.exportUserinfoTemplate, '', { responseType: 'blob' })
export const exportSalaryDetailsApi = (data: any) => post(API.exportSalaryDetails, data, { responseType: 'blob' })
export const exportSalaryDetailsTaxBureauApi = (data: any) => post(API.exportSalaryDetailsTaxBureau, data, { responseType: 'blob' })
export const exportSalaryDetailsPersonnelApi = (data: any) => post(API.exportSalaryDetailsPersonnel, data, { responseType: 'blob' })
export const importUserinfoApi = (data: any) => postFormData(API.importUserinfo, data)
export const querySalaryConfigurationApi = () => get(API.querySalaryConfiguration, '', '')
export const saveSalaryConfigurationApi = (data: any) => post(API.saveSalaryConfiguration, data, '')
export const checkBlenosisApi = (data: any) => post(API.checkBlenosis, data, '')
export const remindSalaryApi = (data: any) => post(API.remindSalary, data, '')
export const queryDeptPrincipalUserApi = () => get(API.queryDeptPrincipalUser, '', '')
export const queryRedactSalaryDetailsApi = (data: any) => post(API.queryRedactSalaryDetails, data, '')
